
//超级管理员
export const AdminList = [
    // {
    //     path: '',
    //     id: 6,
    //     name: '钥匙码管理',
    //     children: [{
    //         path: '/user',
    //         name: '用户列表'
    //     },{
    //         path: '/keybode',
    //         name: '钥匙码列表'
    //     }]

    // } 
    {
        path: '',
        id: 1,
        name: '用户管理',
        children: [{
            path: '/user',
            name: '用户列表'
        }, {
            path: '/role',
            name: '角色管理'
        }]

    },
    {
        path: '',
        id: 2,
        name: '权限管理',
        children: [{
            path: '/permission',
            name: '权限列表'
        }]

    }, {
        path: '',
        id: 3,
        name: '文章管理',
        children: [{
            path: '/category',
            name: '分类管理'
        }, {
            path: '/article',
            name: '文章列表'
        }]

    },{
        path: '',
        id: 4,
        name: '系统设置',
        children: [{
            path: '/log',
            name: '日志管理'
        }, {
            path: '/message',
            name: '留言管理'
        }]

    }

]



//运维管理员
export const OptionsList = [{
    path: '',
    id: 1,
    name: '文章管理',
    children: [{
        path: '/category',
        name: '分类管理'
    }, {
        path: '/article',
        name: '文章列表'
    }]
}]



//会员
export const MemberList = [{
    path: '',
    id: 1,
    name: '文章管理',
    children: [{
        path: '/category',
        name: '分类管理'
    }, {
        path: '/article',
        name: '文章列表'
    }]

}, {
    path: '',
    id: 2,
    name: '系统设置',
    children: [{
        path: '/user',
        name: '日志管理'
    }, {
        path: '/message',
        name: '留言管理'
    }]

}]
