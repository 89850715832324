<template>
    <div class="login-container">
        <div class="login-main">
            <h1>xxx系统管理后台</h1>
            <el-form>
                <el-form-item label="账号">
                    <el-input type="text" v-model="user.account" style="width:180px" />
                </el-form-item>

                <el-form-item label="密码">
                    <el-input type="password" v-model="user.password" style="width:180px" />
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="login">确定</el-button>
                    <el-button>重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { AdminList, MemberList} from '../../router/menuList'
export default {
    data() {
        return {
            user: {
                account: '',
                password: ''
            }
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            localStorage.clear();
        },
        login() {
            let _this=this
            this.$https.post('api/admin/Login',this.user).then(res => {
                if (res.success) {
                    var result = res.data;
                    if (result) {
                        const tokens=result.token;
                        localStorage.setItem('account',_this.user.account)
                        localStorage.setItem('token', tokens.access_token)
                        localStorage.setItem('reftoken', tokens.refresh_token)
                        localStorage.setItem('plist', JSON.stringify(result.plist))//权限列表

                        if(result.roleName=='超级管理员'){
                            localStorage.setItem('menu',JSON.stringify(AdminList))
                        }else{
                            localStorage.setItem('menu',JSON.stringify(MemberList))
                        }
                        this.$router.push('/home');
                    }else{
                        ElMessage({
                            title:'登陆失败',
                            icon:'fail'
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.login-container {
    width: 100%;
    height: 100%;
    background: url('../../static/image/loginbg.jpg') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.login-main {
    width: 600px;
    height: 400px;
    background: white;
    margin-right: 100px;
    border-radius: 5px;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>